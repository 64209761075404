import { defineComponent, computed, h } from "vue"

export default defineComponent({
  name: "ExternalLinkIcon",
  props: {
    size: {
      type: [Number, String],
      default: 24
    }
  },
  setup(props, context) {
    const data = {
      name: "external-link",
      tags: ["outbound"],
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: "currentColor",
        "stroke-width": 2,
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        class: "feather feather-external-link",
        innerHTML:
          '<path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line>'
      }
    }

    const width = computed(() => context.attrs.width || props.size)
    const height = computed(() => context.attrs.height || props.size)

    return () =>
      h("svg", {
        ...data.attrs,
        width: width.value,
        height: height.value
      })
  }
})
