import { defineComponent, computed, h } from "vue"

export default defineComponent({
  name: "FrownIcon",
  props: {
    size: {
      type: [Number, String],
      default: 24
    }
  },
  setup(props, context) {
    const data = {
      name: "frown",
      tags: ["emoji", "face", "bad", "sad", "emotion"],
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: "currentColor",
        "stroke-width": 2,
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        class: "feather feather-frown",
        innerHTML:
          '<circle cx="12" cy="12" r="10"></circle><path d="M16 16s-1.5-2-4-2-4 2-4 2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line>'
      }
    }

    const width = computed(() => context.attrs.width || props.size)
    const height = computed(() => context.attrs.height || props.size)

    return () =>
      h("svg", {
        ...data.attrs,
        width: width.value,
        height: height.value
      })
  }
})
